import useCollection from '@/composables/useCollection'
import { formatDateAsMMMDD } from '@/utils/utils'
import {projectFirestore} from '@/firebase/config'
import filters from '@/utils/filters'
import { generateCampaignItemsHTML, generateCampaignRecipientsHTML } from '@/utils/utils'
import useDocument from '@/composables/useDocument'

export function asdf() {
return `1`
}

export function recipientInviteEmail (client, user, invite, props, campaign){

    const { addDoc: addInvite } = useCollection('invites')
    const { addDoc: addEmail } = useCollection('mail')

    const res = addEmail({
    to: client.email,
    templateId: 'd-9ffec509f4c448f4beaa61dfefcc56ff', // SWAGIBLE:RECIPIENTINVITE
    dynamic_template_data: {
      recipient_first_name: client.name,
      accountHolder_first_name: user.value.displayName,
      campaignExpiryDate: formatDateAsMMMDD(campaign.value.expiryDate.seconds),
      first_name: user.value.displayName, //accountHolder_first_name again :/
      swag_url: `${process.env.VUE_APP_BASE_URL}/invite/${invite.campaignId}/${invite.id}`,
      decline_swag_url: `${process.env.VUE_APP_BASE_URL}/invite/${invite.campaignId}/${invite.id}?showDecline=true`
    }
  })
  return res
}

export function updateRecipientSwagShippedEmail (props) {


    const { addDoc: addEmail } = useCollection('mail')
    addEmail({
        to: props.order.email,
        templateId: 'd-82d2ca02d27c495b9916404ed62db055', // SWAGIBLE:RECIPIENTSWAGSHIPPED
        dynamic_template_data: {
            recipient_first_name: props.order.name,
            tracking_company: 'TODO tracking_company',
            tracking_number: 'TODO tracking_number',
            tracking_url: props.order.trackingLink,
        }
    })
}

export function recipient48HReminderEmail (user, invite, props){
    

    const { addDoc: addEmail } = useCollection('mail')
    addEmail({
    to: invite.email,
    templateId: 'd-f02678b92650435abdcc0740458c6f7b', // SWAGIBLE:RECIPIENTINVITE48HREMINDER
    dynamic_template_data: {
        recipient_first_name: invite.name,
        accountHolder_first_name: user.value.displayName,
        first_name: user.value.displayName,
        campaignExpiryDate: formatDateAsMMMDD(props.campaign.expiryDate.seconds),
        swag_url: `${process.env.VUE_APP_BASE_URL}/invite/${invite.campaignId}/${invite.id}`,
        decline_swag_url: `${process.env.VUE_APP_BASE_URL}/invite/${invite.campaignId}/${invite.id}?showDecline=true`
    }
    })
}

export function adminCampaignReadyEmail (user, props, campaign,productsArr){

    const { addDoc: addEmail } = useCollection('mail')
    addEmail({
        to: 'support@swagible.com',
        templateId: 'd-3f1eab6212b74ed9af79e9b017c30d2b', // MARFAFA:ADMINCAMPREADY
        dynamic_template_data: {
          accountholder_first_name: user.value.displayName,
          accountholder_last_name: user.value.displayName,
          accountholder_email: user.value.email,
          accountholder_company: campaign.accountCompany,
          campaign_name: campaign.campaignName,
          campaign_company: campaign.recipientCompany,
          campaign_expiry: filters.formatDate(campaign.expiryDate, false),
          campaign_recipientsHTML: generateCampaignRecipientsHTML(campaign),
          campaign_itemsHTML: generateCampaignItemsHTML(productsArr),
          dashboard_url: `${process.env.VUE_APP_BASE_URL}/dashboard`
        }
      })
}

export function userCampaignLiveEmail (user) {
    const { addDoc: addEmail } = useCollection('mail')
    addEmail({
        to: user.value.email,
        templateId: 'd-3b3b836460ea4a9586b60ba65605470f', // SWAGIBLE:CAMPAIGNLIVE
        dynamic_template_data: {
          accountHolder_first_name: user.value.displayName,
          dashboard_url: `${process.env.VUE_APP_BASE_URL}/dashboard`
        }
      })
}

export function userAccountConfirmationEmail (user){

    projectFirestore.collection('mail').add({
        to: user.email,
        templateId: 'd-509a943138e345aa8f203ce973b88f59', // SWAGIBLE:EMAILCONF
        dynamic_template_data: {
          confirm_url: process.env.VUE_APP_BASE_URL,
          first_name: user.firstName,
          last_name: user.lastName,
        }
      })
}

export function adminNotificationUserSignup (user) {
    projectFirestore.collection('mail').add({
        to: 'support@swagible.com',
        templateId: 'd-c1d1e313ab6b42e5813d5df880d8436d', // SWAGIBLE:ADMINAHSIGNUP
        dynamic_template_data: {
          accountholder_first_name: user.firstName,
          accountholder_last_name: user.lastName,
          accountholder_email: user.email,
          accountholder_company: user.company,
          dashboard_url: `${process.env.VUE_APP_BASE_URL}/dashboard`
        }
      })
}

export function adminNotificationCampaignCreated (user, account, campaignData, campaign) {
    
    const { addDoc: addEmail } = useCollection('mail')
    addEmail({
        to: 'support@swagible.com',
        templateId: 'd-4b542f7e7b174a418ba2ef06614c1d06', // SWAGIBLE:ADMINCAMPCREATE
        dynamic_template_data: {
          accountholder_first_name: user.value.displayName,
          accountholder_last_name: user.value.displayName,
          accountholder_email: user.value.email,
          accountholder_company: account.value.company,
          campaign_name: campaignData.campaignName,
          campaign_expiry: filters.formatDate(campaignData.expiryDate),
          campaign_recipientsHTML: generateCampaignRecipientsHTML(campaign.value),
          campaign_itemsHTML: generateCampaignItemsHTML(campaign.value.products),
          dashboard_url: `${process.env.VUE_APP_BASE_URL}/dashboard`
        }
      })
}

export function userNotificationRecipientRespondedEmail (invite, addedProducts) {

    const { addDoc: addEmail } = useCollection('mail')
       addEmail({
        to: invite.value.accountEmail,
        templateId: 'd-8dddf31387184bb68868461df0f8fe82', // SWAGIBLE:RECIPIENTRESPONDED
        dynamic_template_data: {
          recipient_first_name: invite.value.name,
          recipient_message_html: generateCampaignItemsHTML(addedProducts.value),
          dashboard_url: `${process.env.VUE_APP_BASE_URL}/dashboard`
        }
      })
}

export function recipientNotificationClaimedSwagEmail (invite) {

    const { addDoc: addEmail } = useCollection('mail')
    addEmail({
        to: invite.value.email,
        templateId: 'd-53acace893d9406c8efbfb23714f5f9d', // SWAGIBLE:RECIPIENTCLAIMEDSWAG
        dynamic_template_data: {
          recipient_first_name: invite.value.name,
          onboarding_url: 'TODO onboarding_url',
        }
      })
}


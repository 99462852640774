import { format, addDays } from 'date-fns'

function zeroDecimalCurrency (amount, currency) {
  let numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol'
  })
  const parts = numberFormat.formatToParts(amount)
  let zeroDecimalCurrency = true
  for (let part of parts) {
    if (part.type === 'decimal') zeroDecimalCurrency = false
  }
  return zeroDecimalCurrency
}

function cleanDate (val) {
  if (val instanceof Date) {
    let valid = (new Date(val)).getTime() > 0
    val = valid ? val : val.toDate()
  } else {
    if (typeof val === 'object') val = val.toDate()
    if (typeof val === 'string') val = new Date(val)
    if (typeof val === 'number') val = new Date(val)
  }
  return val
}

export default {
  cleanDate,

  formatDate (val, showTime = false) {
    return val ? format(cleanDate(val), showTime ? "PP hh:mmaaaaa'm'" : "PP") : null
    // return val ? format(cleanDate(val), showTime ? "MM-dd-yyyy hh:mmaaaaa'm'" : "MM-dd-yyyy") : null
  },

  formatTime (val) {
    return val ? format(cleanDate(val), "hh:mmaaaaa'm'") : null
  },

  // formatMilitaryTime (val) {
  //   if (val < 10) val = `0${val}`
  //   return `${val}:00`
  // },

  formatAmountForStripe (amount, currency)  {
    return zeroDecimalCurrency(amount, currency) ? amount : Math.round(amount * 100)
  },

  hourFilter (hour) {
    let suffix = hour >= 12 ? 'PM' : 'AM'
    let hours = ((hour + 11) % 12 + 1)
    return `${hours}:00 ${suffix}`
  },

  // formatAmountForStripe (amount, currency)  {
  //   return zeroDecimalCurrency(amount, currency) ? amount : Math.round(amount * 100)
  // },

  formatCurrency(amount, currency) {
    const number = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(amount)
    // return `${number} ${currency}`
    const noCurrency = number.substring(2)
    return noCurrency
    const dollars = noCurrency.slice(0, -3)
    return dollars;
    const cents = noCurrency.slice(noCurrency.length - 3)
    return `<span style="white-space: nowrap;">${dollars}<sup class="is-size-7">${cents}</sup></span>`
  }
}
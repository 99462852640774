<template>
  <section v-if="campaign" :class="`hero container ${!showAddress && 'is-fullheight'}`">

    <div v-if="campaign.expiryDate.toDate().getTime() < new Date().getTime()" class="hero-body">
      <h3 class="subtitle">
        Sorry, the invitation has expired.
      </h3>
    </div>

    <div v-else-if="showSuccess" class="hero-body has-text-centered mx-auto" style="max-width: 640px;">
      <h1 class="subtitle is-size-3">
        Congrats!
      </h1>
      <h3 class="is-size-5">
        You have successfully requested some swag
      </h3>

      <form v-if="!messageSent">
        <FormField
          :label="`Send a personal note to ${campaign.accountName}`"
          type="textarea"
          v-model="message"
          class="mt-5"
          id="centered-label"
        />
        <button
          :class="`button is-black is-fullwidth mt-4 ${isPending && 'is-loading'}`"
          :disabled="!message"
          type="button"
          @click="sendMessage()"
        >
          Send message
        </button>
      </form>

      <p class="mt-6">
        Do you want to make your own campaign?
      </p>
      <router-link :to="{ name: 'SignUp' }" class="button is-ghost has-text-weight-bold">
        Sign up
      </router-link>
    </div>

    <div v-else-if="campaign.passcode && !passcodeCracked" class="hero-body is-flex is-flex-direction-column is-justify-content-center">
      <label class="label" style="width: 275px;">Enter passcode</label>
      <div class="field has-addons mb-3">
        <div class="control is-flex-grow-1">
          <input class="input" type="password" v-model="passcode" />
        </div>
        <div class="control">
          <a class="button is-black" @click="testPasscode()">
            Enter
          </a>
        </div>
      </div>

      <p v-if="passcodeError" class="help is-danger">
        {{ passcodeError }}
      </p>
    </div>

    <div v-else-if="!showAddress" class="hero-body">
      <div class="columns">
        <div class="column is-8-tablet is-6-desktop">
          <h3 class="subtitle is-size-3 mb-0">
            Welcome to Swagible!
          </h3>
          <p class="is-size-5">
            <span class="has-text-info">{{ campaign.accountName }}</span> is letting you request a gift
          </p>

          <div v-if="campaign.logoUrl || campaign.message" class="box mt-5">
            <figure v-if="campaign.logoUrl" class="image mx-auto mb-6" style="width: 100px;">
              <img :src="campaign.logoUrl">
            </figure>

            <p v-if="campaign.message" class="subtitle has-text-centered">
              "{{ campaign.message }}"
            </p>
          </div>

          <p class="has-text-centered mb-3">
            At Swagible, your privacy is our top priority.
          </p>
          <p class="has-text-centered mb-3">
            Rest assured, we'll never share your personal information with gift senders. Your address and size choices are kept confidential at all times. Your privacy is our commitment, and it's always protected.
          </p>
        </div>

        <div class="column is-4 px-0">
          <div class="scrollable px-4 border-left">
            <h3 class="subtitle is-size-3 mb-0">
              Items
            </h3>
            <p>
              Select <span class="has-text-info">{{ campaign.chooseType }}</span> items to receive
            </p>

            <Product
              v-for="product in campaign.products"
              :key="product.id"
              type="invite"
              :product="product"
              :isAdded="addedProduct(product)"
              @toggleProduct="toggleProduct"
            />
          </div>
          
          <div class="has-text-right">
            <p class="subtitle">
              {{ addedProducts.length }}/{{ campaign.chooseType }}
            </p>
            <button
              class="button is-black px-6"
              :disabled="!addedProducts.length"
              @click="showAddress = true"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="showAddress" class="hero-body">
      <div class="columns is-justify-content-space-between">
        <div class="column is-8-tablet is-6-desktop">
          <button class="button is-ghost pl-0" @click="showAddress = false">
            <Icon icon="chevron-left" />
            <span>Back</span>
          </button>

          <h3 class="subtitle is-size-3">
            Where should we send your swag?
          </h3>


        <div class="columns is-multiline mt-5">
          <FormField
            label="Email *"
            type="email"
            class="column is-6"
            v-model="email"
          />

          <FormField
            label="Name *"
            class="column is-6"
            v-model="name"
          />

          <FormField
            label="Company *"
            class="column"
            v-model="company"
          />

          <div class="column is-12 field">
            <label class="label">Mailing Address *</label>
            <div class="control">
              <VueGoogleAutocomplete
                id="map"
                classname="input"
                placeholder="Start typing..."
                v-on:placechanged="getAddressData"
              />
            </div>
          </div>
        </div>

        <div :class="`columns is-multiline mt-5 ${!shippingInfo.streetAddress && 'disabled'}`">
          <FormField
            label="Country *"
            class="column is-6"
            v-model="shippingInfo.country"
          />

          <FormField
            label="Address line 1 *"
            class="column is-12"
            v-model="shippingInfo.streetAddress"
          />

          <FormField
            label="Address line 2"
            class="column is-12"
            v-model="shippingInfo.streetAddress2"
          />

          <FormField
            label="Unit/Suit"
            class="column is-4"
            v-model="shippingInfo.unit"
          />

          <FormField
            label="City *"
            class="column is-8"
            v-model="shippingInfo.city"
          />

          <FormField
            label="Province/State *"
            class="column is-6"
            v-model="shippingInfo.province"
          />

          <FormField
            label="Postal code *"
            class="column is-6"
            v-model="shippingInfo.postal"
          />

          <FormField
            label="Phone number"
            class="column is-12"
            v-model="shippingInfo.phone"
          />
        </div>

        <p class="help">* indicates required field</p>
        </div>

        <div class="column is-4 is-flex is-flex-direction-column">
          <div class="box">
            <h3 class="subtitle">
              Items
            </h3>
            <OrderItem
              v-for="product in addedProducts"
              :key="product.id"
              :item="product"
            />
          </div>

          <div class="has-text-right mt-auto">
            <button
              :class="`button is-black ${isPending && 'is-loading'}`"
              :disabled="!email || !name || !company || !shippingInfo.streetAddress || !shippingInfo.city || !shippingInfo.province || !shippingInfo.postal"
              @click="requestInvite()"
            >
              Request My Swag!
            </button>
          </div>

        </div>
      </div>
    </div>

  </section>

  <footer class="has-text-centered p-3">
    <figure class="image mx-auto" style="width: 100px;">
      <img src="@/assets/logo3.svg" />
    </figure>
    <p class="help mt-2">
      &copy;2023 Swagible, Inc. All rights reserved.
    </p>
    <a href="/" target="_blank" class="help has-text-dark">
      Privacy Policy
    </a>
  </footer>

  <div v-if="error" class="section">
    <article class="message is-danger">
      <div class="message-body">
        Could not find this invite
      </div>
    </article>
  </div>

</template>

<script>
import { ref, inject, reactive } from 'vue'
import getDocument from '@/composables/getDocument'
import useCollection from '@/composables/useCollection'
import useDocument from '@/composables/useDocument'
import Product from '@/components/Product/Product.vue'
import AddProductModal from '@/components/Modals/AddProductModal.vue'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import FormField from '@/components/Forms/FormField.vue'
import Icon from '@/components/Icon.vue'
import OrderItem from '@/components/Campaign/OrderItem.vue'
import { timestamp } from '@/firebase/config'
import filters from '@/utils/filters'

export default {
  props: ['campaignId'],
  components: { Product, AddProductModal, VueGoogleAutocomplete, FormField, Icon, OrderItem },
  setup(props) {
    const { document: campaign, error } = getDocument('campaigns', props.campaignId)
    console.log('campaign', campaign)

    const addedProducts = ref([])
    const showAddress = ref(false)
    const email = ref('')
    const name = ref('')
    const company = ref('')
    const isPending = inject('isPending')
    const showSuccess = ref(false)
    const inviteId = ref(null)

    const addedProduct = (product) => {
      return addedProducts.value.find(x => x.id === product.id)
    }

    const toggleProduct = (product) => {
      if (!addedProduct(product)) {
        addedProducts.value.push(product)
        if (addedProducts.value.length > campaign.value.chooseType) addedProducts.value.shift()
      } else {
        const index = addedProducts.value.findIndex(x => x.id === product.id)
        if (index > -1) {
          addedProducts.value.splice(index, 1)
        }
      }
    }

    const shippingInfo = reactive({
      country: '',
      streetAddress: '',
      streetAddress2: '',
      city: '',
      province: '',
      postal: '',
      unit: '',
      longitude: '',
      latitude: '',
      phone: '',
    })

    const getAddressData = (data) => {
      shippingInfo.country = data.country
      shippingInfo.streetAddress = `${data.street_number} ${data.route}`
      shippingInfo.city = data.administrative_area_level_2
      shippingInfo.province = data.administrative_area_level_1
      shippingInfo.postal = data.postal_code
      shippingInfo.latitude = data.latitude
      shippingInfo.longitude = data.longitude
    }

    const requestInvite = async () => {
      isPending.value = true
      const { addDoc: addInvite } = useCollection('invites')
      const { id, clients, products, ...campaignData } = campaign.value
      const invite = await addInvite({
        email: email.value,
        name: name.value,
        company: company.value,
        ...campaignData,
        campaignId: id,
        chosenProducts: addedProducts.value,
        shippingInfo: shippingInfo,
        request: true,
        created: timestamp()
      })
      console.log('inviteinviteinviteinvite', invite)
      inviteId.value = invite.id

      const { addDoc: addNotification } = useCollection('accounts', campaign.value.accountId, 'notifications')
      await addNotification({
        type: 'requestInvite',
        message: `${email.value} has requested swag for ${campaign.value.campaignName}`,
        campaignId: campaign.value.id,
        seen: false,
        created: timestamp()
      })
      isPending.value = false
      showSuccess.value = true
    }

    const messageSent = ref(false)
    const message = ref('')
    const sendMessage = async () => {
      const { updateDoc: updateInvite } = useDocument('invites', inviteId.value)
      isPending.value = true
      await updateInvite({
        feedbackMessage: message.value,
        messageDate: timestamp()
      })
      isPending.value = false
      messageSent.value = true
    }

    const passcodeCracked = ref(false)
    const passcode = ref('')
    const passcodeError = ref('')

    const testPasscode = () => {
      if (passcode.value === campaign.value.passcode) {
        passcodeCracked.value = true
      } else {
        passcodeError.value = 'Code is wrong'
      }
    }

    return {
      campaign,
      error,

      showAddress,
      showSuccess,
      email,
      name,
      company,
      isPending,

      addedProducts,
      addedProduct,
      toggleProduct,

      shippingInfo,
      getAddressData,
      requestInvite,

      messageSent,
      message,
      sendMessage,

      passcodeCracked,
      passcode,
      passcodeError,
      testPasscode,

      ...filters
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 769px) {
  .hero.is-fullheight {
    height: calc(100svh - 100px);
    min-height: 0;
    overflow: visible;
  }

  .border-left {
    border-left: 1px solid;
  }

  .scrollable {
    height: calc(100svh - 10rem);
    overflow-y: scroll;
  }

  .has-offset-top {
    margin-top: 125px;
  }
}

@media screen and (max-width: 768px) {
  .button.is-black {
    width: 100%;
  }
}

.hero.is-fullheight .hero-body {
  display: block;
}

.columns {
  justify-content: space-between;
}

.is-disabled {
  opacity: .5;
  pointer-events: none;
}
</style>
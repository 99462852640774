// import { projectFirestore, timestamp } from '@/firebase/config'
// import useCollection from '@/composables/useCollection'

const onlyNumber = ($event) => {
  let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) $event.preventDefault()
}

function validURL(s) {
  var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  return regexp.test(s)
}

function getMimeType(file, fallback = null) {
	const byteArray = (new Uint8Array(file)).subarray(0, 4)
  let header = ''
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16)
  }
	switch (header) {
    case "89504e47":
      return "image/png"
    case "47494638":
      return "image/gif"
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg"
    default:
      return fallback
  }
}

const campaignIsCompleted = (campaign, invites) => {
  // TODO... remove this!?!?
  if (!campaign.expiryDate) return
  if (campaign.expiryDate.toDate().getTime() < new Date().getTime()) return true
  const some = invites.some(invite => invite.status === 'sent')
  if (campaign.mode === 'invite') return !some
}


const generateCampaignRecipientsHTML = (campaign) => {
  console.log('generateCampaignRecipientsHTML', campaign)
  let rows = ''
  campaign.clients.forEach(client => rows += `
    <tr>
      <td>${client.name}</td>
      <td>${client.email}</td>
    </tr>
  `)

  let string = `
    <table>
      <tr>
        <th>Name</th>
        <th>Email</th>
      </tr>
      ${rows}
    </table>
  `
  console.log('returned', string)
  return string
}

const generateCampaignItemsHTML = (products) => {
  console.log('generateCampaignItemsHTML products', products)

  let string = ''
  products.forEach(product => string += `
    <table border="0" cellpadding="0" cellspacing="0" align="center" width="100%" role="module" style="padding:20px 20px 0px 30px;" bgcolor="#FFFFFF">
      <tbody>
        <tr role="module-content">
          <td height="100%" valign="top">
            <table width="137" style="width:137px; border-spacing:0; border-collapse:collapse; margin:0px 0px 0px 0px;" cellpadding="0" cellspacing="0" align="left" border="0" bgcolor="" class="column column-0">
              <tbody>
                <tr>
                  <td style="padding:0px;margin:0px;border-spacing:0;">
                    <table class="wrapper" role="module" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
                      <tbody>
                        <tr>
                          <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="left">
                            <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px;" width="104" alt="" src="${product.images[0].url}" height="104">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
              </table>
              <table width="137" style="width:137px; border-spacing:0; border-collapse:collapse; margin:0px 0px 0px 0px;" cellpadding="0" cellspacing="0" align="left" border="0" bgcolor="" class="column column-1">
                <tbody>
                  <tr>
                    <td style="padding:0px;margin:0px;border-spacing:0;">
                      <table class="module" role="module" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
                        <tbody>
                          <tr>
                            <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content">
                              <div>
                                <div style="font-family: inherit; text-align: inherit">${product.line1}</div>
                                <div style="font-family: inherit; text-align: inherit">${product.line2}</div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table width="137" style="width:137px; border-spacing:0; border-collapse:collapse; margin:0px 0px 0px 0px;" cellpadding="0" cellspacing="0" align="left" border="0" bgcolor="" class="column column-2">
              <tbody>
                <tr>
                  <td style="padding:0px;margin:0px;border-spacing:0;"></td>
                </tr>
              </tbody>
            </table>
            <table width="137" style="width:137px; border-spacing:0; border-collapse:collapse; margin:0px 0px 0px 0px;" cellpadding="0" cellspacing="0" align="left" border="0" bgcolor="" class="column column-3">
              <tbody>
                <tr>
                  <td style="padding:0px;margin:0px;border-spacing:0;"></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  `)
  
  // console.log('generateCampaignItemsHTML returned', string)
  return string
}

export {
  onlyNumber,

  validURL,
  getMimeType,

  campaignIsCompleted,

  generateCampaignRecipientsHTML,
  generateCampaignItemsHTML,
}


  // Email Date Formatter
export function formatDateAsMMMDD(secondsTimestamp) {
    // Convert seconds to milliseconds
    const milliseconds = secondsTimestamp * 1000;
    
    // Create a Date object
    const date = new Date(milliseconds);
    
    // Array of month abbreviations
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    // Get the month abbreviation
    const month = monthNames[date.getMonth()];
    
    // Get the day, ensuring it is two digits
    const day = String(date.getDate()).padStart(2, '0');

    // Return the formatted date
    return `${month}-${day}`;
    }



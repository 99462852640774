import { ref } from 'vue'
import { projectFirestore } from '@/firebase/config'

const useCollection = (collection, id, subCollection, subId, subSubCollection) => {

  const error = ref(null)
  const isPending = ref(false)
  const docRef = (id && subCollection) ?
    projectFirestore.collection(collection).doc(id).collection(subCollection) :
    projectFirestore.collection(collection)

  const addDoc = async (doc) => {
    error.value = null
    isPending.value = true

    try {
      // only cant is with doc.id it doesnt return anything
      const res = doc.id ? await docRef.doc(doc.id).set(doc) : await docRef.add(doc)
      isPending.value = false
      return res
    }
    catch(err) {
      console.log(err.message)
      error.value = 'could not create the document'
      isPending.value = false
    }
  }

  const addDeepDoc = async (doc) => {
    const deepDocRef = projectFirestore.collection(collection).doc(id).collection(subCollection).doc(subId).collection(subSubCollection)
    error.value = null
    isPending.value = true

    try {
      // only cant is with doc.id it doesnt return anything
      const res = await deepDocRef.add(doc)
      isPending.value = false
      return res
    }
    catch(err) {
      console.log(err.message)
      error.value = 'could not create the document'
      isPending.value = false
    }
  }

  return { error, addDoc, addDeepDoc, isPending }
}

export default useCollection
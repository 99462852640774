<template>
  <article class="media">
    <figure class="media-left">
      <p class="image is-96x96">
        <img :src="item.images[0].url">
      </p>
    </figure>
    <div class="media-content">
      <div class="content product">
        <p class="heading mb-0">{{ item.line1 }}</p>
        <h3 class="subtitle mt-0">{{ item.line2 }}</h3>
        <p class="subtitle" v-html="formatCurrency(item.charge, 'CAD')" />

        <p class="help is-flex is-align-items-center mb-0" id="colors">
          Color(s):
          <button
            v-if="item.chosenColor"
            class="button is-color is-rounded ml-2"
            :style="`--value: ${item.chosenColor}`"
          />
          <div v-else class="buttons ml-2">
            <button
              v-for="item in item.chosenColors"
              :key="item"
              class="button is-color is-rounded"
              :style="`--value: ${item}`"
            />
          </div>
        </p>
        <p v-if="isAdmin && item.chosenSex" class="help is-flex is-align-items-center mb-0">
          Sex: {{ item.chosenSex }}
        </p>
        <p v-if="isAdmin && item.chosenSize" class="help is-flex is-align-items-center mb-0">
          Size: {{ item.chosenSize }}
        </p>

        <p v-if="item.logo" class="help">
          File: <a :href="item.logo.downloadUrl" target="_blank">{{ snippet(item.logo.name) }}</a>
        </p>
      </div>
    </div>
  </article>
</template>


<script>
import Color from '@/components/Product/Color.vue'
import filters from '@/utils/filters'

export default {
  props: ['item', 'isAdmin'],
  components: { Color },
  setup() {

    const snippet = (string) => {
      const arr = string.split('.')
      return string.length > 25 ? string.substring(0, 20) + '.' + arr[1] : string
    }

    return {
      snippet,
      ...filters,
    }
  }
}
</script>

<style scoped>
#colors .button {
  background: var(--value);
  border: 2px solid #dbdbdb;
}

.media + .media {
  border-color: #22211F33;
}

a {
  color: var(--info);
}

@media screen and (min-width: 769px) {
  .help {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 14vw;
  }
}
</style>
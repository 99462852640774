<template>
  <div class="buttons">
    <button
      v-for="item in options"
      :key="item"
      :class="`button tabButton is-rounded ${isActive(target, item.value) && 'is-black'}`"
      :style="`--value: ${item.value}`"
      @click="$emit('change', item.value)"
    >
      {{ item.label }}
    </button>
  </div>
</template>

<script>
export default {
  props: ['options', 'target'],
  emits: ['change'],
  setup() {
    
    const isActive = (one, two) => {
      return JSON.stringify(one) === JSON.stringify(two)
    }

    return {
      isActive
    }
  }
}
</script>

<style scoped>
.buttons {
  flex-wrap: wrap;
}
</style>
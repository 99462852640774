<template>
  <div class="field">
    <label :class="`label level is-mobile is-clickable ${!isExpanded && 'has-text-grey'}`" @click="isExpanded = !isExpanded">
      {{ title }}
      <i :class="`fa-solid ${isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'}`"></i>
    </label>
    <p v-if="isExpanded">{{ info }}</p>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: ['title', 'info'],
  setup() {
    const isExpanded = ref(false)

    return {
      isExpanded
    }
  }
}
</script>

<style scoped>
p {
  white-space: pre-wrap;
}
</style>

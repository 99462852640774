<template>
  <!-- <router-view v-slot="{ Component }">
    <Transition name="fade" mode="out-in" appear>
      <component :is="Component"></component>
    </Transition>
  </router-view> -->

  <router-view />
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
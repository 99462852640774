<template>
  <FormField
    v-if="type === 'single'"
    id="colors"
    :label="'Select a color'"
    type="tabs"
    :options="colors.map(color => {
      return { label: null, value: color }
    })"
    v-model="product.chosenColor"
  />

  <div v-else>
    <label class="label">
      {{ colors.length > 1 ? 'Selected colors' : 'Selected color' }}
    </label>
    <ItemChooser
      id="colors"
      :class="colors.length < 2 && 'is-unclickable'"
      :items="colors.map(color => {
        return { label: null, value: color }
      })"
      :selectedItems="product.chosenColors"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import FormField from '@/components/Forms/FormField.vue'
import ItemChooser from '@/components/Forms/ItemChooser.vue'

export default {
  props: ['type', 'product'],
  components: { FormField, ItemChooser },
  setup(props) {

    if (props.product.images.length === 1 || props.type !== 'single') {
      props.product.chosenColors = [props.product.images[0].color]
    }

    const colors = computed (() => {
      const arr = props.type === 'single' ? props.product.chosenColors : props.product.images.map(item => item.color)
      return [...new Set(arr)]
    })

    const addedColor = (color) => {
      if (props.type === 'single') {
        return props.product.chosenColor === color
      } else {
        return props.product.chosenColors.find(_color => _color === color)
      }
    }

    const toggleColor = (color) => {
      if (props.type === 'single') {
        props.product.chosenColor = color
      } else {
        if (!addedColor(color)) {
          props.product.chosenColors.push(color)
        } else {
          const index = props.product.chosenColors.indexOf(color)
          if (index > -1) {
            props.product.chosenColors.splice(index, 1)
          }
        }
      } 
    }

    const chosenColor = ref(null)

    return {
      colors,
      // addedColor,
      toggleColor,

      chosenColor
    }
  }
}
</script>

<style>
#colors .button {
  background: var(--value);
  border: 2px solid #dbdbdb;
}

#colors .button.is-dark {
  border: 2px solid #fff;
  outline: 2px solid #6a6a6a;
}

.is-unclickable {
  pointer-events: none;
}
</style>
import { ref } from 'vue'
import { projectAuth } from '@/firebase/config'

const user = ref(projectAuth.currentUser)

projectAuth.onAuthStateChanged(_user => {
  user.value = _user
})

const getUser = () => {
  // if (user.value && user.value.photoURL === 'buyer') user.value.buyer = true 
  return { user } 
}

export default getUser
import { ref, watchEffect } from 'vue'
import { projectFirestore } from '@/firebase/config'

const getCollection = (collection, query, docID, subCollection, subId, subSubCollection) => {
  const documents = ref(null)
  const error = ref(null)

  let collectionRef
  if (subId && subSubCollection) {
    collectionRef = projectFirestore.collection(collection).doc(docID).collection(subCollection).doc(subId).collection(subSubCollection).orderBy('created', 'desc')
  } else {
    collectionRef = (docID && subCollection) ?
      projectFirestore.collection(collection).doc(docID).collection(subCollection).orderBy('created', 'desc') :
      projectFirestore.collection(collection).orderBy('created', 'desc')
  }

  if (query) {
    collectionRef = collectionRef.where(...query)
  }

  const unsub = collectionRef.onSnapshot(snap => {
    let results = []
    snap.docs.forEach(doc => {
      doc.data().created && results.push({...doc.data(), id: doc.id })
    });

    // update values
    documents.value = results
    error.value = null
  }, err => {
    console.log(collection, subCollection, err.message)
    documents.value = null
    error.value = 'could not fetch the data'
  })

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  })

  return { error, documents }
}

export default getCollection
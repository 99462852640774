import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { projectAuth } from './firebase/config'
// import './assets/bulma.css'
// import './assets/bulma-list.css'
// import './assets/bulma-tooltip.css'
// import './assets/style.css'
import './assets/concat.css'

let app

projectAuth.onAuthStateChanged((user) => {  
  if (!app) {
    app = createApp(App)
      .use(router)
      .mount('#app')
  }
})
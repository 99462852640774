<template>
  <div class="product card is-fullheight is-clickable" @click="showModal = true">
    <div class="card-content is-fullheight is-flex is-flex-direction-column">
      <button
        class="button is-small is-rounded is-black"
        style="position: absolute; top: .25rem; right: .25rem; z-index: 5;"
      >
        <span class="icon">
          <i v-if="isAdded" class="fa-solid fa-check"></i>
          <i v-else class="fa-solid fa-plus"></i>
        </span>
        <span v-if="isAdded">Added</span>
        <span v-else>Add</span>

        <span
          v-if="isAdded"
          class="icon ml-2 pl-3"
          style="border-left: 1px solid #363636; margin-right: -2px;"
          v-on:click.stop
          @click="$emit('toggleProduct', product)"
        >
          <i class="fa-solid fa-trash"></i>
        </span>
      </button>

      <figure v-if="product.images[0]" class="image mx-auto mb-3">
        <img :src="product.images[0].url">
      </figure>
      <p class="heading mt-auto">{{ product.line1 }}</p>
      <h4 class="subtitle">{{ product.line2 }}</h4>
      <p v-if="type !== 'invite'" class="price" v-html="formatCurrency(product.charge, 'CAD')" /> 
    </div>
  </div>

  <AddProductModal
    v-if="showModal"
    :type="type"
    :product="product"
    :isAdded="isAdded"
    @toggleProduct="$emit('toggleProduct', product), showModal = false"
    @close="closeAddProductModal()"
  />
</template>

<script>
import { ref } from 'vue'
import Color from '@/components/Product/Color.vue'
import AddProductModal from '@/components/Modals/AddProductModal.vue'
import filters from '@/utils/filters'

export default {
  props: ['type', 'product', 'isAdded'],
  emits: ['toggleProduct'],
  components: { Color, AddProductModal },
  setup(props, { emit }) {
    const showModal = ref(false)

    // const addProduct = () => {
    //   if (!props.isAdded) {
    //     showModal.value = true
    //   } else {
    //     emit('toggleProduct', props.product)
    //   }
    // }

    const closeAddProductModal = () => {
      showModal.value = false
      if (!props.type === 'invite') {
        product.chosenColors = []
      }
    }

    return {
      showModal,
      // addProduct,
      closeAddProductModal,
      ...filters
    }
  }
}
</script>

<style scoped>
.card {
  box-shadow: none;
  text-align: center;
}

p {
  white-space: nowrap;
}

.button.is-black {
  font-size: 14px;
  min-width: 0;
}
</style>
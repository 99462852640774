<template>
  <div
    v-if="!image.src"
    :class="`file ${isBoxed && 'is-boxed'} ${!hasRights && 'disabled'}`"
    @dragover.prevent @drop.prevent
  >
    <label class="file-label" @drop="chooseFile">
      <input class="file-input" type="file" @change="chooseFile">
      <!-- <span class="button is-fullwidth">
        <span class="file-label">
          {{ progress ? 'Uploading...' : 'Choose a file...' }}
          Choose a file...
        </span>
      </span> -->
      <span class="file-cta">
        <span class="file-icon">
          <i class="fas fa-upload"></i>
        </span>
        <span class="file-label">
          {{ isPending ? 'Uploading...' : 'Choose a file...' }}
        </span>
      </span>
    </label>
  </div>

  <template v-else>
    <Cropper
      ref="cropper"
      class="upload-example-cropper"
      :src="image.src"
    />

    <div class="level mb-0 mt-3">
      <button class="button is-white" @click="reset()">
        Reset
      </button>
      <button class="button" @click="crop()">
        Save
      </button>
    </div>
  </template>
</template>

<script>
import { ref, inject } from 'vue'
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { getMimeType } from '@/utils/utils'

export default {
  props: ['isBoxed'],
  emits: ['uncropped', 'fileSelected'],
  components: { Cropper },
  setup(_, { emit }) {
    const isPending = inject('isPending')
    const hasRights = ref(false)

    let filename = ''
    const image = ref({
      src: null,
			type: null
    })
    const cropper = ref(null)

    const chooseFile = (e) => {
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0]

      console.log('file', file)
      emit('uncropped', true)

      if (image.value.src) URL.revokeObjectURL(image.value.src)

      const blob = URL.createObjectURL(file)
      filename = file.name

      const reader = new FileReader()
      reader.onload = (e) => {
        image.value = {
          src: blob,
          type: getMimeType(e.target.result, file.type),
        }
      }
      reader.readAsArrayBuffer(file)
    }

    const reset = () => {
			image.value = {
				src: null,
				type: null
			}
		}

    const crop = () => {
			const { canvas } = cropper.value.getResult()
			canvas.toBlob((blob) => {
        blob.name = filename
        emit('fileSelected', blob)
        emit('uncropped', false)
			}, image.value.type)
		}

    return {
      isPending,
      hasRights,
      image,
      cropper,
      chooseFile,
      reset,
      crop
    }
  }
};
</script>

<style scoped>
.file.is-boxed .file-label {
  width: 100%;
}

.file.is-boxed .file-cta {
  text-align: center;
}
</style>

<style>
.vue-advanced-cropper__foreground {
  opacity: .7;
  background-image: url('~@/assets/checkered.png');
}
</style>
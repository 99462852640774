<template>
  <AppUser
    v-if="user && ![
      'SignUp',
      'Invite',
      'InvitePublic',
      'CreateCampaign',
      'Landing',
      'Experience',
      'Solutions',
      'Catalog',
      'Contact',
      'Faqs',
      'Terms',
      'JoinBeta'
    ].includes(route.name)"
  />
  
  <RouterTransition v-else />

</template>

<script>
import { provide, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import RouterTransition from '@/router/RouterTransition'
import AppUser from '@/AppUser'

export default {
  components: { RouterTransition, AppUser },
  setup() {
    const route = useRoute()

    const { user } = getUser()
    // console.log('user', user.value)

    const { documents: notifications } = getCollection('accounts', null, user?.value?.uid, 'notifications')
    provide('notifications', notifications)

    const isPending = ref(false)
    provide('isPending', isPending)

    // console.log('process.env.', process.env)

    return {
      route,
      user,
      notifications,
      isPending,
    }
  }
}
</script>

<style scoped>
.column.is-3 {
  white-space: nowrap;
  min-width: 204px;
  max-width: 264px;
}
.column.is-3 + .column {
  min-width: 1012px;
}
</style>
<template>
  <div :class="`field ${disabled && 'disabled'}`">
    <label class="label">
      {{ label }}
    </label>

    <div v-if="type === 'select'" class="control">
      <div class="select is-fullwidth">
        <select :value="JSON.stringify(modelValue)" @change="$emit('update:modelValue', JSON.parse($event.target.value))">
          <option
            v-for="option in options"
            :key="option.value"
            :value="JSON.stringify(option.value)"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>

    <div v-else-if="type === 'tabs'">
      <Tabs
        @change="$emit('update:modelValue', $event)"
        :target="modelValue"
        :options="options"
      />
      <slot />
    </div>

    <div v-else-if="type === 'increment'" class="control has-icons-left has-icons-right">
      <input class="input is-medium has-text-centered" type="text" :value="modelValue">
      <span class="icon is-left has-text-white is-clickable" @click="$emit('update:modelValue', modelValue - 1)">
        <i class="fas fa-minus"></i>
      </span>
      <span class="icon is-right has-text-white is-clickable" @click="$emit('update:modelValue', modelValue + 1)">
        <i class="fas fa-plus"></i>
      </span>
    </div>

    <div v-else-if="type === 'textarea'" class="control">
      <textarea
        class="textarea"
        :placeholder="placeholder"
        :value="modelValue" 
        @input="$emit('update:modelValue', $event.target.value)"
      ></textarea>
      <slot />
    </div>

    <div v-else-if="type === 'info'" class="control">
      <p>{{ modelValue }}</p>
    </div>

    <div v-else-if="type === 'tel'" class="field-body">
      <div class="field is-expanded">
        <div class="field has-addons">
          <p class="control">
            <a class="button is-static has-text-grey has-text-weight-normal">+1</a>
          </p>
          <p class="control is-expanded">
            <input
              class="input"
              type="tel"
              :placeholder="label"
              :value="modelValue"
              minlength="10"
              maxlength="10"
              @keypress="onlyNumber"
              @input="$emit('update:modelValue', $event.target.value)"
              @blur="validate"
            >
          </p>
        </div>
      </div>
    </div>

    <div v-else-if="type === 'money'" class="control has-icons-left has-icons-right">
      <input
        class="input"
        type="tel"
        :value="modelValue"
        @keypress="onlyNumber"
        @input="$emit('update:modelValue', $event.target.value)"
        @wheel="$event.target.blur()"
      />
      <span class="icon is-left">$</span>
      <span class="icon is-right">CAD</span>
    </div>

    <div v-else class="control has-icons-right">
      <input
        class="input"
        :type="
          type === 'password' ? passwordType :
          !type ? 'text' : type
        "
        :placeholder="placeholder ? placeholder : label"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @blur="validate"
      >

      <span v-if="type === 'password'" class="icon is-right is-clickable" @click="passwordType = passwordType === 'password' ? 'text' : 'password'">
        <i :class="`fa-solid ${passwordType === 'password' ? 'fa-eye' : 'fa-eye-slash'}`"></i>
      </span>

      <slot />

    </div>
    <p v-if="error" class="help is-danger">{{ error }}</p>
    <p v-if="help" class="help">{{ help }}</p>
  </div>
</template>

<script>
import { ref } from 'vue'
import Tabs from '@/components/Forms/Tabs'

export default {
  props: ['label', 'type', 'placeholder', 'options', 'modelValue', 'help', 'disabled'],
  components: { Tabs },
  setup(props) {
    const error = ref(null)
    const passwordType = ref('password')

    const focusNextInput = ($event) => {
      if (!$event.path) return
      const next = $event.path[2].nextSibling
      if (next) {
        const inputs = next.querySelectorAll('input,select')
        if (inputs.length) inputs[0].focus()
      } else {
        $event.path[3].nextElementSibling.focus()
      }
    }

    const onlyNumber = ($event) => {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) $event.preventDefault()
    }

    const validate = ($event) => {
      if (props.type === 'tel' && $event.target.value.length < 10 ) {
        error.value = 'Please enter a valid phone number'
        return
      }
      if (props.type === 'email' && !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test($event.target.value)) {
        error.value = 'Please enter a valid email'
        return
      }

      error.value = null
    }

    return {
      error,
      passwordType,
      // focusNextInput,
      onlyNumber,
      validate,
    }
  }
}
</script>

<style scoped>
.label {
  text-align: left;
}

.textarea:not([rows]) {
  min-height: 6em;
}

.field.hide-error .help {
  display: none;
}
</style>

<style>
.control.has-icons-left .input, .control.has-icons-left .select select {
  padding-left: 1.5rem !important;
}
.control.has-icons-left .icon.is-left {
  width: 1.5rem !important;
}

#centered-label .label {
  text-align: center;
}
</style>
import { toRefs, reactive } from 'vue'
import firebase from 'firebase'
import 'firebase/firestore'
import { projectStorage } from '@/firebase/config'
import getUser from '@/composables/getUser'
const { user } = getUser()

const useStorage = (directory) => {
  let state = reactive({
    error: null,
    loading: false,
    progress: 0,
    resultData: {}
  })

  const uploadData = async (fileData) => {
    state.error = null
    state.loading = true
    state.progress = 0

    let uniquePathName = `${directory}/${user.value.uid}/${fileData.name}`
    const storageRef = projectStorage.ref(uniquePathName)

    try {
      let uploadTask = storageRef.put(fileData, {
        contentType: fileData.type
      })

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        _progress => {
          var value = _progress.bytesTransferred / _progress.totalBytes
          state.progress = value
        },
        _error => {
          state.error = _error
          state.loading = false
        },
        async _complete => {
          state.error = null
          state.loading = false

          let downloadUrl = await uploadTask.snapshot.ref.getDownloadURL()
          let storageData = {
            downloadUrl,
            name: uploadTask.snapshot.metadata.name,
            image: {
              ref: uploadTask.snapshot.ref.fullPath,
              size: uploadTask.snapshot.metadata.size,
              contentType: uploadTask.snapshot.metadata.contentType,
              timeCreated: uploadTask.snapshot.metadata.timeCreated
            }
          }

          state.resultData = {
            ...storageData
          }
          state.progress = 0
        }
      );
    } catch (_error) {
      console.log(_error.message)
      state.loading = false
      state.error = _error
    }
  }

  const deleteData = async (path) => {
    state.error = null
    state.loading = true
    const storageRef = projectStorage.ref(path);

    try {
      await storageRef.delete()
    } catch (_error) {
      console.log(_error.message)
      state.loading = false
      state.error = _error
    }
  }

  return {
    ...toRefs(state),
    uploadData,
    deleteData
  }
}

export default useStorage
import { ref } from 'vue'
import { projectFirestore } from '@/firebase/config'

const useDocument = (collection, id, subCollection, subId, subSubCollection, subSubId) => {

  const error = ref(null)
  const isPending = ref(false)
  const docRef = (subCollection && subId) ?
    projectFirestore.collection(collection).doc(id).collection(subCollection).doc(subId) :
    projectFirestore.collection(collection).doc(id)

  const deleteDoc = async () => {
    isPending.value = true
    error.value = null

    try {
      const res = await docRef.delete()
      isPending.value = false
      return res
    }
    catch(err) {
      isPending.value = false
      error.value = `could not delete the document. ${err.message}`
    }
  }

  const updateDoc = async (updates) => {
    isPending.value = true
    error.value = null

    try {
      const res = await docRef.update(updates)
      isPending.value = false
      return res
    }
    catch(err) {
      console.log(err.message)
      isPending.value = false
      error.value = 'could not update the document'
    }
  }

  const updateDeepDoc = async (updates) => {
    const deepDocRef = projectFirestore.collection(collection).doc(id).collection(subCollection).doc(subId).collection(subSubCollection).doc(subSubId)
    isPending.value = true
    error.value = null

    try {
      const res = await deepDocRef.update(updates)
      isPending.value = false
      return res
    }
    catch(err) {
      console.log(err.message)
      isPending.value = false
      error.value = 'could not update the document'
    }
  }

  return { error, isPending, deleteDoc, updateDoc, updateDeepDoc }

}

export default useDocument
<template>
  <aside class="menu p-3 pt-5 is-hidden-mobile">
    <router-link :to="{ name: isAdmin ? 'Admin' : 'Dashboard' }" class="mb-5">
      <figure class="image" style="width: 48px;">
        <img src="@/assets/logo2.svg">
      </figure>
    </router-link>

    <ul v-if="isAdmin" class="menu-list">
      <router-link :to="{ name: 'Admin' }">
        <Icon icon="fa-solid fa-house" />
        <span class="tag">Dashboard</span>
      </router-link>
      <router-link :to="{ name: 'Products' }">
        <Icon icon="fa-solid fa-shirt" />
        <span class="tag">Products</span>
      </router-link>
    </ul>
    
    <ul v-else class="menu-list">
      <li>
        <router-link :to="{ name: 'Dashboard'}">
          <Icon icon="fa-solid fa-house" />
          <span class="tag">Dashboard</span>
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Assets'}">
          <Icon icon="fa-solid fa-image" />
          <span class="tag">Assets</span>
        </router-link>
      </li>
    </ul>
   
    <ul v-if="!isAdmin" class="menu-list">
      <li>
        <router-link :to="{ name: 'Account'}">
          <Icon icon="settings" />
          <span class="tag">Settings</span>
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Payments'}">
          <Icon icon="bank" />
          <span class="tag">Payments</span>
        </router-link>
      </li>
    </ul>

    <ul class="menu-list mt-auto">
      <li>
        <button class="button is-fullwidth" @click="logOut()">
          <Icon icon="fa-solid fa-arrow-right-from-bracket" />
        </button>
      </li>
    </ul>
  </aside>

  <nav class="navbar is-fixed-bottom is-hidden-tablet" role="navigation">
    <div v-if="isAdmin" class="navbar-brand">
      <router-link :to="{ name: 'Admin' }" class="navbar-item">
        <Icon icon="fa-solid fa-house" />
        <p class="is-size-7">Dashboard</p>
      </router-link>
      <router-link :to="{ name: 'Products' }" class="navbar-item">
        <Icon icon="fa-solid fa-shirt" />
        <p class="is-size-7">Products</p>
      </router-link>
    </div>

    <div v-else class="navbar-brand">
      <router-link :to="{ name: 'Dashboard' }" class="navbar-item">
        <Icon icon="fa-solid fa-house" />
        <p class="is-size-7">Home</p>
      </router-link>
      <router-link :to="{ name: 'Assets' }" class="navbar-item">
        <Icon icon="fa-solid fa-image" />
        <p class="is-size-7">Assets</p>
      </router-link>
      <router-link :to="{ name: 'Account' }" class="navbar-item">
        <Icon icon="fa-solid fa-gear" />
        <p class="is-size-7">Settings</p>
      </router-link>
    </div>
  </nav>
</template>

<script>
import getUser from '@/composables/getUser'
import useLogout from '@/composables/useLogout'
import Icon from '@/components/Icon.vue'

export default {
  components: { Icon },
  setup() {
    const { user } = getUser()
    const isAdmin = user.value.uid === process.env.VUE_APP_ADMIN_ID ? true : false

    const logOut = async () => {
      const { logout } = useLogout()
      await logout()
      window.location.href = window.location.origin
    }

    return {
      isAdmin,
      logOut,
    }
  }
}
</script>

<style scoped>
.navbar.is-fixed-bottom {
  border-top: 1px solid var(--border);
  padding-bottom: env(safe-area-inset-bottom);
}

a.navbar-item {
  flex: 1;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  border-top: 3px solid transparent;
}


a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, 
a.navbar-item.router-link-active {
  color: var(--primary);
  border-top: 3px solid var(--primary);
}


/* Side menu */
.menu {
  background: #fff;
  border-right: 1px solid var(--border);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 15;
  width: 68px;
}

.menu-list a p {
  display: none;
}

.menu-list a {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 2rem;
  font-weight: 500;
}

.menu-list a .icon {
  margin-right: 1rem;
}

.menu-list a.router-link-exact-active {
  background: whitesmoke;
}

.menu-list a .tag {
  position: absolute;
  left: 5.5em;
  top: .85rem;
  background: var(--primary);
  color: #fff;
  padding-top: 2px;
  padding-bottom: 2px;
  height: auto;
  display: none;
}
.menu-list a:hover .tag {
  display: block;
}
</style>
<template>
  <div class="buttons">
    <button
      v-for="item in items"
      :key="item"
      :class="`button is-rounded ${selected(item.value) && 'is-dark'}`"
      :style="`--value: ${item.value}`"
      @click="selectItems(item.value)"
    >
      {{ item.label }}
    </button>
  </div>
</template>

<script>
export default {
  props: ['items', 'selectedItems'],
  setup(props) {
    const selected = (item) => props.selectedItems.find(selectItem => {
      return selectItem === item
    })

    const selectItems = (item) => {
      if (selected(item)) {
        const index = props.selectedItems.indexOf(item)
        if (index > -1) props.selectedItems.splice(index, 1)
        return
      }
      props.selectedItems.push(item)
    }

    return {
      selected,
      selectItems
    }
  }
}
</script>
<template>
  <label class="label level is-mobile mt-3">
    <span class="has-text-weight-bold">Logo</span>
    <button v-if="logos && logos.length && !showUpload" class="button is-ghost" @click="showUpload = true, $emit('selectedFile', null)">
      New file
    </button>
  </label>

  <div
    v-if="logos && logos.length && !showUpload"
    :class="`dropdown ${showAssets && 'is-active'}`"
    @click="showAssets = !showAssets"
  >
    <div class="dropdown-trigger">
      <button class="button" aria-haspopup="true">
        <span class="icon mr-2">
          <i class="fa-solid fa-image"></i>
        </span>
        <span>{{ selectedAsset ? snippet(selectedAsset.name) : snippet(logos[0].name) }}</span>
        <span class="icon is-small">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu4" role="menu">
      <div class="dropdown-content">
        <template v-for="logo in logos" :key="logo.id">
          <div class="dropdown-item level is-mobile mb-0 is-clickable" @click="selectedAsset = logo">
            <figure class="image is-32x32 mr-3">
              <img :src="logo.downloadUrl">
            </figure>
            <p class="mr-auto">{{ snippet(logo.name) }}</p>
          </div>
          <hr class="dropdown-divider">
        </template>
      </div>
    </div>
  </div>

  <!-- <UploadFile
    v-if="!blankProductImage.url"
    target="productImages"
    :deletePath="null"
    :isBoxed="true"
    @progress="isPending = $event"
    @imageUploaded="blankProductImage.url = $event.downloadUrl"
  /> -->

  <UploadFile
    v-else
    @fileSelected="uploadAsset"
    @uncropped="$emit('uncropped', $event)"
  />
</template>

<script>
import { ref, watch } from 'vue'
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import useStorage from '@/composables/useStorage'
import useCollection from '@/composables/useCollection'
import UploadFile from '@/components/UploadFile_OLD'
import { timestamp } from '@/firebase/config'

export default {
  emits: ['selectedFile', 'uncropped'],
  components: { UploadFile },
  setup(_, { emit }) {
    const { user } = getUser()
    const { documents: logos } = getCollection('accounts', null, user.value.uid, 'logos')
    const { uploadData, error, resultData } = useStorage('assets')

    const showUpload = ref(false)
    const showAssets = ref(false)
    const selectedAsset = ref(null)

    const uploadAsset = async (file) => {
      await uploadData(file)
      showUpload.value = false
    }

    const doAddLogo = async (fileInfo) => {
      const { addDoc: addLogo } = useCollection('accounts', user.value.uid, 'logos')
      await addLogo({
        ...fileInfo,
        tags: [],
        created: timestamp()
      })
    }

    watch(() => resultData.value, (resultData) => {
      doAddLogo(resultData)
    })

    watch(() => logos.value, (logos) => {
      emit('selectedFile', logos[0])
    })

    watch(() => selectedAsset.value, (asset) => {
      emit('selectedFile', asset)
    })

    const snippet = (string) => {
      const arr = string.split('.')
      return string.length > 25 ? string.substring(0, 20) + '.' + arr[1] : string
    }

    return {
      logos,

      showUpload,
      showAssets,
      selectedAsset,

      uploadAsset,
      error,

      snippet
    }
  },
}
</script>

<style scoped>
.dropdown, .dropdown-trigger, .dropdown-menu {
  width: 100%;
}

.dropdown .button {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dropdown-content {
  display: block;
}
</style>
<template>  
  <!-- Don't use modal component here -->

  <div v-if="product" class="modal is-active">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <span class="icon close is-clickable" aria-label="close" @click="$emit('close')">
          <i class="fas fa-xmark"></i>
        </span>
      </header>

      <section class="modal-card-body columns mb-0">        
        <div class="column is-6">

          <figure v-if="currentImage" class="image" ref="productImageContainer">
            <img :src="currentImage.url">
            <figure v-if="product.logo" class="image" :style="computedLogoPosition">
              <img :src="product.logo.downloadUrl">
            </figure>
          </figure>

          <div class="columns is-multiline is-mobile">
            <div
              v-for="image in product.images"
              :key="image.url"
              class="column is-3 is-clickable"
              @click="currentImage = image"
            >
              <figure class="image">
                <img :src="image.url">
              </figure>
          </div>
          </div>
        </div>

        <div class="product column is-6 pb-6">
          <div class="pb-5">
            <p class="heading">{{ product.line1 }}</p>
            <div class="level mb-3">
              <h2 class="subtitle mb-0">
                {{ product.line2 }}
              </h2>
              <span v-if="type !== 'invite'" v-html="formatCurrency(product.charge, 'CAD')" />
            </div>

            <!-- Invite Modal Product Start (with Sizes)-->
            <div v-if="type === 'invite'" class="mt-3">
              <Size
                v-if="product.sizes && product.sizes.length > 1"
                :product="product"
              />
                          
              <FormField
                v-if="product.sexes && product.sexes.length > 1"
                label="Sex:"
                type="tabs"
                :options="product.sexes.map(sex => {
                  return { label: sex, value: sex }
                })"
                v-model="product.chosenSex"
              />
              <Color
                v-if="product.chosenColors && product.chosenColors.length > 1"
                type="single"
                :product="product"
              />
            </div>
            <!-- Invite Modal Product End (with Sizes)-->
            <div v-else class="mt-3">
              <Color
                :product="product"
              />
              <Assets
                @selectedFile="doAddLogo"
                @uncropped="setUncropped"
              />
            </div>

            <!-- Product Add Button in Modal -->
            <button
              class="button is-black is-fullwidth my-5"
              :disabled="addProductDisabled(product)"
              @click="isAdded ? $emit('close') : $emit('toggleProduct', product)"
            >
              {{ isAdded ? 'Update' : type === 'invite' ? 'Select product' : 'Add to campaign' }}
            </button>
            <!-- Product Add Button in Modal -->

            <InfoBox
              v-if="type !== 'invite' && product.description"
              title="Details"
              :info="product.description"
            />

            <InfoBox
              v-if="type !== 'invite' && product.restrictions"
              title="Restrictions"
              :info="product.restrictions"
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import Assets from '@/components/Assets.vue'
import Size from '@/components/Product/Size.vue'
import Color from '@/components/Product/Color.vue'
import InfoBox from '@/components/InfoBox.vue'
import FormField from '@/components/Forms/FormField.vue'
import filters from '@/utils/filters'

export default {
  props: ['type', 'product', 'isAdded'],
  emits: ['toggleProduct', 'close'],
  components: { Assets, Size, Color, InfoBox, FormField },
  setup(props) {
    const productImageContainer = ref(null)

    const currentImage = ref(props.product.images[0])

    const sexes = computed(() => {
      return [...new Set(props.product.images.map(item => item.sex))]
    })

    props.product.chosenSex = sexes.value[0]

    watch(() => props.product.chosenSex, (chosenSex) => {
      let arr = []
      props.product.images.forEach(item => {
        if (item.sex === chosenSex) arr.push(item.color)
      })
      if (arr.length) {
        props.product.chosenColors = arr
        props.product.chosenColor = arr[0]
      }
    })

    watch(() => props.product.chosenColor, (chosenColor) => {
      currentImage.value = props.product.images.find(image => image.color === chosenColor)
    })

    const computedLogoPosition = computed(() => {
      const { logoConstaints } = props.product
      if (!logoConstaints || !productImageContainer.value) return
      const diff = productImageContainer.value.offsetWidth / 480
      return `
        position: absolute;
        left: ${logoConstaints.x * diff}px;
        top: ${logoConstaints.y * diff}px;
        width: ${logoConstaints.w * diff}px;
        height: ${logoConstaints.h * diff}px;`
    })

    const showAssets = ref(false)
    const uncropped = ref(false)

    const setUncropped = (bool) => {
      uncropped.value = bool
    }

    const doAddLogo = (data) => {
      // if no data it pushes undefined and the updateCampaign doesn't work
      if (data) {
        props.product.logo = data
        uncropped.value = false
      }
    }

    const addProductDisabled = (product) => {
      if (uncropped.value) return true
      if (props.type === 'invite') {
        if (product.chosenColors.length && !product.chosenColor ) return true
        if (sexes.value.length > 1 && !product.chosenSex) return true
        if (product.sizes.length > 1 && !product.chosenSize) return true
      } else {
        if (product.images.length > 1 && !product.chosenColors.length) return true
      }
      return false
    }

    if (props.type === 'invite' && props.product.chosenColors.length === 1) {
      props.product.chosenColor = props.product.chosenColors[0]
    }

    return {
      currentImage,
      productImageContainer,
      computedLogoPosition,

      showAssets,
      uncropped,
      setUncropped,
      doAddLogo,

      sexes,
      addProductDisabled,
      ...filters
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 769px) {
  .image {
    max-width: 180px;
    margin: auto;
  }
}
</style>
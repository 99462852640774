import { watchEffect, ref } from 'vue'
import { projectFirestore } from '@/firebase/config'

const getDocument = (collection, id, subCollection, subId, subSubCollection, subSubId) => {
  let document = ref(null)
  let error = ref(null)
  let loading = ref(true)

  // register the firestore collection reference

  let docRef = (subCollection && subId) ?
    projectFirestore.collection(collection).doc(id).collection(subCollection).doc(subId) :
    projectFirestore.collection(collection).doc(id)

  if (subSubCollection && subSubId) {
    docRef = projectFirestore.collection(collection).doc(id).collection(subCollection).doc(subId).collection(subSubCollection).doc(subSubId)
  }

  const unsub = docRef.onSnapshot(doc => {
    // need to make sure the doc exists & has data
    if(doc.data()) {
      document.value = {...doc.data(), id: doc.id}
      error.value = null
      loading.value = false
    }
    else {
      error.value = 'that document does not exist'
    }
  }, err => {
    console.log(err.message)
    error.value = 'problem fetching the document'
  })

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub())
  })

  return { error, document, loading }

}

export default getDocument
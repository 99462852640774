<template>
  <Menu />

  <section :class="$route.name" id="container">
    <RouterTransition />
  </section>
</template>

<script>
import { provide, computed } from 'vue'
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import RouterTransition from '@/router/RouterTransition'
import Menu from '@/components/Menu'

export default {
  components: { RouterTransition, Menu },
  setup() {
    const { user } = getUser()

    const { documents: notifications } = getCollection('accounts', null, user.value.uid, 'notifications')
    provide('notifications', notifications)
    const filteredNotifications = computed(() => {
      return notifications.value && notifications.value.filter(notification => {
        const diff = new Date().getTime() - notification.created.toDate().getTime()
        // return diff < 9999990000
        return diff < 10000
      })
    })

    const { documents: paymentMethods } = getCollection('stripe_customers', null, user.value.uid, 'payment_methods')
    provide('paymentMethods', paymentMethods)

    return {
      user,
      filteredNotifications,
    }
  }
}
</script>

<style scoped>
#container {
  width: 100vw;
}

@media screen and (min-width: 769px) {
  #container {
    margin-left: 68px;
    width: calc(100vw - 68px);
  }
}
</style>

<style scoped>
@media screen and (max-width: 768px) {
  #container > section {
    padding-top: 2rem;
  }
}
</style>
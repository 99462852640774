import { createRouter, createWebHistory } from 'vue-router'
import { projectAuth } from '@/firebase/config'
import Invite from '@/views/Invites/Invite.vue'
import InvitePublic from '@/views/Invites/InvitePublic.vue'

const requireAuth = (to, from, next) => {
  if (!projectAuth.currentUser) {
    next({ name: 'Landing' })
  } else if (projectAuth.currentUser.uid === process.env.VUE_APP_ADMIN_ID) {
    next({ name: 'Admin' })
  // } else if (projectAuth.currentUser && !projectAuth.currentUser.photoURL) {
  //   next({ name: 'CreateCampaign' })
  } else {
    next()
  }
}

const requireAdmin = (to, from, next) => {
  if (!projectAuth.currentUser) {
    next({ name: 'Landing' })
  } else if (projectAuth.currentUser.uid != process.env.VUE_APP_ADMIN_ID) {
    next({ name: 'Landing' })
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: () => import('@/views/Landing.vue'),
    // beforeEnter: (to, from, next) => {
    //   if (projectAuth.currentUser) {
    //     next({ name: 'Dashboard' })
    //   } else {
    //     next()
    //   }
    // }
  },
  {
    path: '/the-experience',
    name: 'Experience',
    component: () => import('@/views/Experience.vue'),
  },
  {
    path: '/solutions',
    name: 'Solutions',
    component: () => import('@/views/Solutions.vue'),
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: () => import('@/views/Catalog.vue'),
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('@/views/Auth/SignUp.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/Auth/ForgotPassword.vue'),
  },
  {
    path: '/create-campaign/:campaignId',
    name: 'CreateCampaign',
    component: () => import('@/views/CreateCampaign.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Campaigns.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/campaign/:campaignId',
    name: 'ViewCampaign',
    component: () => import('@/views/ViewCampaign.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/assets',
    name: 'Assets',
    component: () => import('@/views/Assets.vue'),
    beforeEnter: requireAuth
  },
  // {
  //   path: '/invoices',
  //   name: 'Invoices',
  //   component: () => import('@/views/Invoices.vue'),
  //   beforeEnter: requireAuth
  // },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/Admin/AdminDashboard.vue'),
    beforeEnter: requireAdmin
  },
  {
    path: '/orders/:campaignId',
    name: 'AdminViewCampaign',
    component: () => import('@/views/Admin/AdminViewCampaign.vue'),
    props: true,
    beforeEnter: requireAdmin
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('@/views/Admin/AdminProducts.vue'),
    beforeEnter: requireAdmin
  },
  {
    path: '/invite/:campaignId/:inviteId',
    name: 'Invite',
    component: Invite,
    props: true
  },
  {
    path: '/invite/:campaignId',
    name: 'InvitePublic',
    component: InvitePublic,
    props: true
  },

  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/Settings.vue'),
    beforeEnter: requireAuth,
    children: [
      {
        path: '',
        name: 'Account',
        component: () => import('@/views/Settings/Account.vue'),
      },
      {
        path: 'payments/:paymentId?',
        name: 'Payments',
        component: () => import('@/views/Settings/Payments.vue'),
      },
    ],
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component: () => import('@/views/Faqs.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/views/Terms.vue')
  },
  {
    path: '/join-beta',
    name: 'JoinBeta',
    component: () => import('@/views/JoinBeta.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  // DEBUG
  console.log(`Navigating to: ${to.path}`);
  console.log(`Matched routes: `, to.matched);
  if (to.matched.length === 0) {
    console.warn(`No route matched for path: ${to.path}`);
  }
  next()
})

export default router
